<template>
  <div class="publicGuidelines">
    <div class="publicTitle">注销指南</div>
    <div class="publicListMain">
      <div class="publicTitle">办理流程</div>
      <ul>
        <li>插入Ukey</li>
        <li class="publicOverlength">证书信息确认</li>
        <li>上传资料</li>
        <li>资料审核</li>
        <li>业务完成 </li>
      </ul>
    </div>
    <div class="publicListMain">
      <div class="publicTitle">注意事项</div>
      <ol>
        <li>（1）确认是否下载并安装【豸信CA数字证书助手驱动】。如“未安装”，请“<a class="blue" @click="$util.downloadById(CONFIG.APPID)">下载安装</a>”。（安装完成后请刷新页面）</li>
        <li>（2）将需要注销的Ukey插入电脑USB接口，Ukey密码验证成功后进行注销。（若遇到控件拦截，需要全部运行控件）</li>
        <li>（3）上传资料审核通过后即可完成注销，推荐使用【IE】、【Chrome】、【Edge】、【360】浏览器。</li>
        <li class="publicSpecial"><span class="el-icon-warning"></span>注销请插入一个Ukey操作，以免造成数据冲突，造成注销失败。</li>
      </ol>
    </div>
    <div class="publicListMain">
      <div class="publicTitle">所需证明资料 </div>
      <div>
        <div class="publicMeans">1、【机构证书】</div>
        <p>（1）业务单提交后，下载《数字证书注销申请表》并填写完整，加盖公章</p>
        <p>（2）营业执照（机构证件）原件复印件，加盖公章</p>
        <p>（3）法人身份证原件复印件，加盖公章</p>
        <p>（4）本次业务办理人身份证原件复印件，加盖公章</p>
      </div>
      <div>
        <div class="publicMeans publicOther">2、【机构个人证书】</div>
        <p>（1）业务单提交后，下载《数字证书注销申请表》并填写完整，加盖公章</p>
        <p>（2）营业执照（机构证件）原件复印件，加盖公章</p>
        <p>（3）法人身份证原件复印件，加盖公章</p>
        <p>（4）证书持有人身份证原件复印件，加盖公章</p>
        <p>（5）本次业务办理人身份证原件复印件，加盖公章</p>
      </div>

    </div>
    <div class="publicBtn">
      <el-button @click="certCancel" type="primary">注销申请</el-button>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    certCancel () {
      this.$parent.Ukey.connect()
        .then(certSn => {
          this.$api.getCertBySn({ certSn, transType: 4 }).then(res => {
            if (res.code === '0') {
              this.$parent.certInfo = { ...res.data, certSn }
              this.$parent.agreementShow = true
            } else if (res.code === '306') {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '继续办理',
                type: 'warning'
              }).then(() => {
                this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
              })
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                callback: () => { }
              })
            }
          })
        })
        .catch(err => {
          if (err.message) this.$message.error(err.message)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.helptext {
  padding: 40px 64px;
  background: #ffffff;
  color: #303b50;
  .title {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }
  .step {
    background: #ecf3ff;
    border: 1px solid #9dbcf7;
    padding: 40px 60px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1px;
    margin-bottom: 40px;
    position: relative;
    .hint {
      font-size: 14px;
      font-weight: bold;
      line-height: 19px;
      margin-top: 24px;
    }
    .n {
      width: 40px;
      height: 36px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      div {
        position: relative;
        font-size: 14px;
        color: #ffffff;
        line-height: 26px;
        z-index: 9;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-top: 26px solid #3e7ff7;
        border-left: 20px solid #3e7ff7;
        border-right: 20px solid #3e7ff7;
        border-bottom: 10px solid transparent;
      }
    }
  }
  .text {
    margin-bottom: 90px;
    & > div {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 1px;
      span {
        color: #ff5846;
      }
    }
  }
  .submitButn {
    display: block;
    margin: 0 auto;
    width: 182px;
    margin-bottom: 40px;
  }
}
</style>