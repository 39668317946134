import { UkeyMiddleware, PARAM } from '@/utils/ukeyMiddleware'
import { MessageBox } from 'element-ui'
// import request from '@/utils/request'
function getErr (err) {
  return err ? { message: err } : {}
}
export class Ukey {
  // eslint-disable-next-line space-before-function-paren
  constructor(self) {
    this.self = self
    this.certInfo = {}
    this.applicationName = 'wellhope'
    this.containerName = 'LAB_USERCERT'
    this.ukeyMiddleware = new UkeyMiddleware('admin')
  }
  connect () {
    return this.ukeyMiddleware.connect().then(res => {
      if (res.code !== 0) {
        const h = this.self.$createElement
        MessageBox.alert(
          h('p', undefined, [
            h('span', undefined, '您还未安装豸信CA数字证书助手，请'),
            h(
              'a',
              {
                on: {
                  click: () => {
                    this.self.$util.downloadById(this.self.CONFIG.APPID)
                  }
                }
              },
              '【下载安装】'
            ),
            h('span', undefined, '后进行注销')
          ]),
          '提示',
          {
            type: 'warning',
            confirmButtonText: '好的'
          }
        )
        throw getErr(false)
      } else {
        return this.ukeyMiddleware.handle('GetUkeyList', {})
      }
    }).then(res => {
      if (res.code === 0 && res.data.length > 0) {
        // return Promise.resolve(res.data)
        this.ukeyName = res.data[0]['uKey-alias']
        return this.disconnectUkey()
      } else {
        MessageBox.alert(
          '请插入需要注销的Ukey。',
          '提示',
          {
            type: 'warning',
            confirmButtonText: '好的'
          }
        )
        throw getErr(false)
      }
    }).then(() => {
      return this.ukeyMiddleware.handle('ConnectUkey', { 'uKey-alias': this.ukeyName, applicationName: this.applicationName, containerName: this.containerName })
    }).then(res => {
      if (res.code !== 0) { throw getErr('Ukey链接失败') }
      this.devHandle = res.data.devHandle
      return this.ukeyMiddleware.handle('ExportMyUserCert', { devHandle: this.devHandle, CertType: 1 })
    }).then(res => {
      if (res.code !== 0) { throw getErr('读取证书信息失败') }
      let base64Cert = res.data.certContent
      this.base64Cert = base64Cert
      return this.ukeyMiddleware.handle('GetCertSpecificInfo', { base64Cert, infoType: PARAM.SGD_CERT_SERIAL })
    }).then(res => {
      if (res.code !== 0) { throw getErr('读取证书信息失败') }
      this.certSn = res.data.certInfo.toLowerCase()
      return Promise.resolve(this.certSn)
    })
  }
  ukeyLogin (userPin) {
    userPin = userPin + ''

    return this.ukeyMiddleware.handle('UkeyLogin', { devHandle: this.devHandle, userPin, appName: '' }).then(res => {
      if (res.code !== 0) {
        if (res.code === 0x0A000024) {
          throw getErr('口令错误，请重新输入,剩余可验证次数' + res.data.retryCount + '次')
        } else {
          throw getErr(res.msg || '登录失败，请稍候再试')
        }
      }

      return Promise.resolve(res)
    })
  }

  disconnectUkey () {
    if (this.devHandle && this.devHandle !== '') {
      let devHandle = this.devHandle
      this.devHandle = ''
      return this.ukeyMiddleware.handle('DisconnectUkey', { devHandle })
    } else {
      return new Promise((resolve) => { resolve() })
    }
  }
}
