<template>
  <el-dialog class="ukeyLogin" @open="open" @close="close" title="Ukey验证" top="10%" center :visible.sync="dialogShow" width="440px">
    <el-form label-position="left" label-width="98px" size="small">
      <el-form-item label="证书序列号">
        <el-input disabled v-model="certSn"></el-input>
      </el-form-item>
      <el-form-item :error="error" :show-message="false" label="Ukey密码">
        <el-input type="password" placeholder="请输入Ukey密码" maxlength="8" autocomplete="new-password" v-model.number="userPin"></el-input>
        <el-input v-show="false"></el-input>
      </el-form-item>
      <template v-if="error">
        <div class="error" v-if="error==='1'">介质已锁定，您可以申请<router-link :to="{name:'certUnlock'}">介质解锁</router-link>
        </div>
        <div v-else class="error">{{error}}</div>
      </template>
    </el-form>
    <el-button class="submitButn" @click="login" size="small" type="primary">验证</el-button>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: 'show',
    event: 'showChange'
  },
  props: ['show', 'certSn', 'Ukey'],
  data() {
    return {
      userPin: '',
      dialogShow: false,
      error: ''
    }
  },
  created() {
    this.dialogShow = this.show
  },
  methods: {
    close() {
      this.$emit('showChange', false)
    },
    login() {
      if (this.userPin) {
        this.Ukey.ukeyMiddleware
          .handle('UkeyLogin', {
            devHandle: this.Ukey.devHandle,
            userPin: this.userPin + '',
            appName: ''
          })
          .then(res => {
            if (res.code !== 0) {
              if (res.code === 0x0a000024) {
                this.error = `口令错误，请重新输入,剩余可验证次数${res.data.retryCount}次`
              } else if (res.code === 0x0a000025) {
                // 介质已锁定，您可以申请<a harf="#/service/certUnlock">介质解锁</a>
                this.error = '1'
              } else {
                this.error = res.msg || '登录失败，请稍候再试'
              }
            } else {
              this.error = ''
              this.dialogShow = false
              this.$emit('logined')
            }
          })
      } else {
        this.error = '请输入Ukey密码'
      }
    },
    open() {
      // this.Ukey.connect()
      //   .then(certSn => {
      //     this.certSn = certSn
      //     this.$api.getCertBySn({ certSn, transType: 3 }).then(res => {
      //       console.log(res)
      //     })
      //   })
      //   .catch(err => {
      //     this.$message.error(err.message)
      //   })
    }
  },
  watch: {
    show() {
      this.dialogShow = this.show
      if (!this.show) {
        this.userPin = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.ukeyLogin {
  color: #303b50;
  .el-dialog--center {
    padding: 0 18px;
    .el-dialog__header {
      padding: 0;
      text-align: left;
      border-bottom: #dee2e8 1px solid;
      .el-dialog__title {
        line-height: 42px;
        font-size: 14px;
        font-weight: bold;
      }
      .el-dialog__body {
        padding: 32px 36px;
      }
    }
  }
  .el-form {
    margin-bottom: 8px;
    .el-form-item {
      height: 36px;
      background: #f3f4fa;
      border-radius: 4px;
      border: 1px solid #d5dbe2;
      margin-bottom: 32px;
      &.is-error {
        border-color: #ed5448;
      }
      .el-form-item__label {
        line-height: 22px;
        margin: 6px 0;
        padding: 0 12px;
        border-right: 1px #d8e0eb solid;
      }
      .el-form-item__error {
        left: -98px;
        // line-height: 36px;
        padding-top: 8px;
      }
      .el-input {
        border: none;
        .el-input__inner {
          border: none !important;
          background: none !important;
          padding: 0 15px;
        }
      }
    }
    .error {
      font-size: 12px;
      color: #ed5448;
      line-height: 18px;
      margin-top: -24px;
      text-align: left;
    }
  }
  .submitButn {
    display: block;
    margin: 0 auto;
    width: 70px;
  }
}
</style>